<template>
  <div class="ant-card mt-5 mb-10" style="min-height:150px;">
    <div class="card-header">
      <h1 class="float-left mt-3  ml-4">Phone Number</h1>

      <div class="text-center float-right">
        <v-dialog v-model="dialog" width="500">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="primary"
              class="mt-3 mr-5"
              dark
              v-bind="attrs"
              v-on="on"
              v-if="!phone"
            >
              Add Phone Number
            </v-btn>
          </template>
          <v-alert
            :type="actionClass"
            class="mr-15"
            v-if="message"
            dismissible
          >
            {{ message }}
          </v-alert>
          <v-card>
            <v-form ref="add_phone" lazy-validation>
              <v-card-title class="headline ">
                Add Phone number
              </v-card-title>

              <v-card-text>
                <v-container>
                  <vue-tel-input-vuetify
                    v-model="phone_number"
                    required

                    @input="validatephone"
                  ></vue-tel-input-vuetify>
                </v-container>
              </v-card-text>

              <v-divider></v-divider>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" text @click="dialog = false">
                  Cancel
                </v-btn>
                <v-btn color="primary" text @click="phone_submit">
                  Submit
                </v-btn>
              </v-card-actions>
            </v-form>
          </v-card>
        </v-dialog>
      </div>
    </div>

    <div class="text-center mt-5 pb-5" v-if="!phone">
      <i class="fas fa-phone fa-5x mb-4"></i>
      <h6>You do not have any phone numbers</h6>
      <p class="mb-5">Please add any phone numbers to project your profile.</p>
    </div>

    <v-row v-if="phone" class="mt-5 mx-5">
       <v-col>
        {{ phone ? phone : "N/A" }}
      </v-col>

      <v-col class="text-center">
        <v-btn color="green" depressed x-small>
          Verified
        </v-btn>
      </v-col>

      <v-col>

      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import validationMxn from '@/mixins/validation_mixin';

export default {
  name: "phoneComponent",
  mixins: [validationMxn],
  props: ['user'],
  data() {
    return {
      phone: this.user.phone,
      dialog: false,
      actionClass: '',
      message: '',
      phone_number: '',
    };
  },
  computed: {
    ...mapGetters(['getSession']),
  },
  methods: {
    PhoneChange() {
      if (this.$refs.add_phone.validate()) {
        alert("phone validated");
      }
    },
    // validatephone(formattedNumber, { number, valid, country }) {
    // },
    async phone_submit() {
      if (!this.$refs.add_phone.validate()) {
        return;        
      }
      const payload = {
        phone_number: this.phone_number,
      };
      const sessionData = this.getSession;
      const fullPayload = {
        params: payload,
        endpoint: `/auth/user-update/${sessionData.id}`
      };

      const response = await this.performUpdateActions(fullPayload);
      this.actionClass = response.data.status === 'success' ? 'success' : 'error';
      this.message = response.data.message;
      this.phone = response.data.status === 'success' ? response.data.data.phone_number : '';
    },

  }
};
</script>
